import { BsArrowUpRight } from 'react-icons/bs';
import * as S from './styles.js';

const DigitalPresence = () => {
  const handleStartProjectClick = () => {
    const whatsappNumber = '+5511942413999'; // Replace with your actual number
    const message = 'Olá, estou interessado em iniciar um projeto com vocês.';
    const whatsappUrl = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(
      message,
    )}`;
    window.open(whatsappUrl, '_blank');
  };

  return (
    <div id='sanchesSection'>
      <S.Container>
        <S.Column>
          <S.Title>
            Transforme Sua Presença Digital Globalmente com a Sanches
          </S.Title>
          <S.StartProjectButton onClick={handleStartProjectClick}>
            Inicie um projeto
            <BsArrowUpRight />
          </S.StartProjectButton>
        </S.Column>
        <S.ColumnImage>
          <S.Image
            src='/assets/homepage/digital-presence.svg'
            alt='Imagem Representativa'
          />
        </S.ColumnImage>
        <S.Column>
          <S.Text>
            Especialistas em unir tecnologia avançada e design criativo, a
            Sanches Creative oferece soluções personalizadas que escalam
            negócios globalmente. Nosso foco está em criar estratégias digitais
            que integram CRM, data e experiências omnichannel, elevando sua
            marca no mercado internacional.
          </S.Text>
        </S.Column>
      </S.Container>
    </div>
  );
};

export default DigitalPresence;
