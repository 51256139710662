// style.js
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  margin: 8em 0;
  padding: 1em 2em;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
    margin: 2em 0;
  }
`;

export const ColumnImage = styled.div`
  flex: 1;
  img {
    width: 120%;
  }
`;

export const Column = styled.div`
  flex: 1;
  padding: 0 2em;
`;

export const Title = styled.h1`
  text-align: left;
  font-size: 58px;
  font-weight: 500;
  color: #434957;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 32px;
  }
`;

export const SubTitle = styled.h3`
  text-align: left;
  color: #434957;
  font-size: 18px;
  font-weight: 500;
  margin-top: 5px;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const StartProjectButton = styled.button`
  border: none;
  font-size: 15px;
  background-color: #f1ffee;
  border: 1px solid #434957;
  color: #434957;
  width: 250px;
  height: 60px;
  margin-top: 1.8em;
  border-radius: 12px;
  padding: 16px 32px;
  display: flex;
  border: none;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  flex-shrink: 0;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  z-index: 1;

  &:hover {
    opacity: 0.9;
    background-color: #434957;
    border: 1px solid #f1ffee;
    color: #f1ffee;

    svg {
      fill: #f1ffee;
    }
  }

  svg {
    font-size: 22px;
    fill: #434957;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: auto;
`;

export const Text = styled.p`
  text-align: left;
  color: #434957;
  font-size: 18px;
  font-weight: 300;
  padding-left: 4em;
  margin-top: 5px;

  @media (max-width: 768px) {
    padding-left: 0;
  }
`;
