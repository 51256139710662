import { RiTerminalFill } from 'react-icons/ri';
import * as S from './styles';

const uiGlobal = '/assets/homepage/global-inovate-ui.svg';
const whatsappNumber = '+5511942413999';

export default function Banner() {
  const message = 'Olá, estou interessado em iniciar um projeto com vocês.';
  const whatsappUrl = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(
    message,
  )}`;

  const handleWhatsAppRedirect = () => {
    // Enviar evento para o Google Analytics
    window.gtag('event', 'contato_whatsapp', {
      event_category: 'Contato',
      event_label: 'WhatsApp',
      value: 'Iniciar Projeto',
    });

    window.open(whatsappUrl, '_blank');
  };

  return (
    <>
      <S.BannerWrapper>
        <S.Column>
          <S.Title>
            Inovação<br></br>Global
            <S.TextImage src={uiGlobal} height={62} alt='UI Sanches' />
            <br></br>em{' '}
            <b>
              Futuros<br></br>Digitais
            </b>
          </S.Title>
          <S.CallToAction onClick={handleWhatsAppRedirect}>
            <RiTerminalFill />
            Comece um projeto
          </S.CallToAction>
          {/* Link oculto para permitir a cópia do endereço do WhatsApp com clique direito */}
          <a href={whatsappUrl} style={{ display: 'none' }}>
            Link WhatsApp
          </a>
        </S.Column>
        <S.ColumnWithBackground />
      </S.BannerWrapper>
    </>
  );
}
