// styles.js
import styled from 'styled-components';

export const SolutionsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  gap: 1.2em;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const SolutionButton = styled.a`
  display: none; // inicialmente oculto
  padding: 10px 20px;
  background-color: #434957;
  color: #f1ffee;
  text-align: center;
  border-radius: 5px;
  text-decoration: none;
  margin-top: 10px;

  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  display: none;
  border-radius: 8px;
  svg {
    font-size: 22px;
  }
  &:hover {
    display: flex;
    color: #434957;
    background-color: #f1ffee;
    align-items: center;
    justify-content: space-between;
  }
`;

export const BoxIcon = styled.img``;

export const SolutionBox = styled.div`
  flex: 1;
  padding: 4em;
  max-width: 100%;

  background: linear-gradient(
    180deg,
    rgba(248, 255, 246, 0) 26.04%,
    #f8fff6 100%
  );

  transition: all 0.3s ease;
  &:hover ${SolutionButton} {
    display: block; // mostrar o botão ao passar o mouse
  }

  @media (max-width: 768px) {
    ${SolutionButton} {
      display: block; // sempre visível em dispositivos móveis
    }

    padding: 3em;
  }
`;

export const SolutionTitle = styled.h3`
  color: #434957;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 38.4px */
  margin-top: 2em;
  img {
  }

  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

export const SolutionText = styled.p`
  color: #434957;
  min-height: 180px;
  font-size: 16.273px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24.41px */
  letter-spacing: -0.163px;

  @media (max-width: 768px) {
    min-height: 120px;
    line-height: 120%; /* 24.41px */
  }
`;

export const CallToAction = styled.button`
  text-align: left;
  color: #434957;
  font-size: 18px;
  font-weight: 500;
  border: none;
  padding: 0;
  margin-top: 2em;
  background-color: transparent;
  cursor: pointer;
  gap: 1em;
  display: flex;

  transition: all 0.2s ease-in-out;
  @media (max-width: 768px) {
    font-size: 18px;
  }
  &:hover {
    text-decoration: underline;
  }
`;
