import { LuSendHorizonal } from 'react-icons/lu';
import * as S from './styles'; // Importando os estilos

const StayConnected = () => {
  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    // Substitua 'URL_DO_SEU_BACKEND' pela URL do seu serviço de backend
    fetch('URL_DO_SEU_BACKEND', {
      method: 'POST',
      body: formData,
    })
      .then((response) => {
        // Trate a resposta do seu backend aqui
        if (response.ok) {
          alert('Solicitação enviada com sucesso!');
        } else {
          alert('Falha ao enviar solicitação.');
        }
      })
      .catch((error) => {
        // Trate erros de rede aqui
        console.error('Houve um erro ao enviar a solicitação:', error);
      });
  };

  return (
    <div id='contactSection'>
      <S.StayConnectedContainer>
        <S.Content>
          <S.TextSpace>VAMOS COMEÇAR</S.TextSpace>
          <S.Title>
            Você está pronto <b>para iniciarmos?</b>
          </S.Title>
          <S.Text>
            O trabalho em colaboração com os nossos clientes é essencial para um
            projeto incrível.
          </S.Text>
        </S.Content>
        <S.RevertColumn>
          <S.ImageColumn />
          <S.ContentColumn>
            <S.ContactForm onSubmit={handleSubmit}>
              <S.FormField>
                <S.Label htmlFor='name'>DIGITE SEU NOME</S.Label>
                <S.Input
                  type='text'
                  id='name'
                  name='name'
                  placeholder='•••••••••'
                />
              </S.FormField>

              <S.FormField>
                <S.Label htmlFor='email'>E-MAIL</S.Label>
                <S.Input
                  type='email'
                  id='email'
                  name='email'
                  placeholder='•••••••••'
                />
              </S.FormField>
              <S.FormField>
                <S.Label htmlFor='telefone'>TELEFONE</S.Label>
                <S.Input
                  type='tel'
                  id='telefone'
                  name='telefone'
                  placeholder='•••••••••'
                />
              </S.FormField>

              <S.FormField>
                <S.Label htmlFor='mensagem'>DIGITE SUA MENSAGEM</S.Label>
                <S.TextArea
                  id='mensagem'
                  name='mensagem'
                  rows={2}
                  placeholder='•••••••••'
                />
              </S.FormField>
              <S.SubmitButton type='submit'>
                Enviar solicitação <LuSendHorizonal />
              </S.SubmitButton>
            </S.ContactForm>
          </S.ContentColumn>
        </S.RevertColumn>
      </S.StayConnectedContainer>
    </div>
  );
};

export default StayConnected;
