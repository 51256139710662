import { RiTerminalFill } from 'react-icons/ri';
import * as S from './styles';

const iconCRM = '/assets/icons/crm-data.svg';
const iconTech = '/assets/icons/technology.svg';
const iconUXUI = '/assets/icons/ux-ui.svg';

const whatsappNumber = '+5511942413999';

const solutionsData = [
  {
    title: 'Inovação e Eficiência Tecnológica',
    icon: iconTech,
    text: 'Desenvolvemos soluções tecnológicas avançadas que atendem as necessidades de um mercado global. Nossos projetos são focados em escalabilidade, desempenho e inovação, preparando seu negócio para o futuro digital',
  },
  {
    title: 'Experiências Digitais Excepcionais!',
    icon: iconUXUI,
    text: 'Criamos experiências de usuário que transcendem fronteiras. Nosso design UX & UI é centrado no usuário, combinando estética e funcionalidade para interfaces intuitivas e envolventes em qualquer lugar do mundo.',
  },
  {
    title: 'Transforme seus Dados em Estratégias ',
    icon: iconCRM,
    text: 'Nossa abordagem em CRM e gestão de dados é estratégica e global. Implementamos soluções que coletam e analisam dados com precisão, proporcionando insights valiosos para decisões de negócios inteligentes e personalizadas',
  },
];

export default function Solutions() {
  const message = 'Olá, estou interessado em iniciar um projeto com vocês.';
  const whatsappUrl = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(
    message,
  )}`;

  const handleWhatsAppRedirect = (event) => {
    window.gtag('event', 'contato_whatsapp', {
      event_category: 'Contato',
      event_label: 'WhatsApp',
      value: 'Iniciar Projeto',
    });

    window.open(whatsappUrl, '_blank');
  };

  return (
    <div id='solutionsSection'>
      <S.SolutionsContainer>
        {solutionsData.map((solution, index) => (
          <S.SolutionBox key={index}>
            <S.BoxIcon
              src={solution.icon}
              alt={solution.title}
              width={90}
              height={90}
            />
            <S.SolutionTitle>{solution.title}</S.SolutionTitle>
            <S.SolutionText>{solution.text}</S.SolutionText>
            <S.CallToAction onClick={handleWhatsAppRedirect}>
              <RiTerminalFill />
              Comece um projeto
            </S.CallToAction>
            {/* Link oculto para permitir a cópia do endereço do WhatsApp com clique direito */}
            <a href={whatsappUrl} style={{ display: 'none' }}>
              Link WhatsApp
            </a>
          </S.SolutionBox>
        ))}
      </S.SolutionsContainer>
    </div>
  );
}
