import styled from 'styled-components';

export const BannerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0em 0em 0em 4em;
  background-color: #f1ffee;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 4em 0 0;
    justify-content: flex-end;
    height: 90vh;
  }
`;

export const TextImage = styled.img``;
export const Title = styled.h1`
  text-align: left;
  font-size: 58px;
  font-weight: 500;
  color: #434957;
  margin: 0;
  @media (max-width: 768px) {
    font-size: 44px;
    img {
      margin-bottom: -8px;
      height: 48px;
    }
  }
  img {
    margin-bottom: -8px;
    margin-left: 12px;
  }
`;

export const CallToAction = styled.button`
  text-align: left;
  color: #434957;
  font-size: 18px;
  margin-top: 2em;
  padding: 0;
  font-weight: 500;
  border: none;

  background-color: transparent;
  cursor: pointer;
  gap: 1em;
  display: flex;

  transition: all 0.2s ease-in-out;
  @media (max-width: 768px) {
    font-size: 18px;
  }
  &:hover {
    text-decoration: underline;
  }
`;

export const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding-right: 8em;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 2em;
    justify-content: flex-end;
    margin: 0;
    width: 100%;
  }
`;

export const ColumnWithBackground = styled.div`
  background-image: url('/assets/homepage/banner.jpg');
  background-size: cover;
  background-position: center;
  object-fit: cover;
  width: 60vw;
  height: 90vh;
  border-radius: 0 0 0 31px;
  @media (max-width: 768px) {
    width: 100vw;
    height: 40vh;
    margin-top: 2em;
    border-radius: 0 0 0 0;
  }
`;

export const ContactButton = styled.button`
  // ... (additional styling)
`;

export const IconWrapper = styled.div`
  // ... (additional styling)
`;

export const UiTitle = styled.img`
  // ... (additional styling)
`;
