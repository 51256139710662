import styled, { keyframes } from 'styled-components';

export const Icon = styled.img`
  width: 24px; // Adjust based on your design
  height: 24px; // Adjust based on your design
`;

export const SkillTitle = styled.h3`
  font-size: 1em;
  font-weight: 500;
  color: #333;
`;
// styles.js

// Cria uma animação keyframe que move o slide da direita para a esquerda
const marqueeAnimation = keyframes`
  from { transform: translateX(0); }
  to { transform: translateX(-100%); }
`;

export const SliderContainer = styled.div`
  display: flex;
  overflow-x: hidden;
  white-space: nowrap;
  position: relative;
`;

export const Slide = styled.div`
  display: inline-flex; // Use inline-flex para que os slides fiquem lado a lado
  padding: 1rem;
  min-width: 24%; // Cada slide tem a largura do contêiner
  white-space: normal; // Permite que o texto dentro do slide quebre normalmente
  animation: ${marqueeAnimation} 5s linear infinite; // Aplica a animação

  @media screen and (max-width: 768px) {
    min-width: 90%;
  }
`;

export const SlideContent = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
`;
