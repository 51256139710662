import { useEffect, useState } from 'react';
import { IoClose } from 'react-icons/io5';
import * as S from './styles';

const iconLightGreen = '/assets/header/icon-light-green.svg';

export default function ModalMenu({ onClose }) {
  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(true);
  }, []);

  const handleClose = () => {
    setVisible(false);
    setTimeout(onClose, 400);
  };

  const handleMenuItemClick = (sectionId) => {
    document.getElementById(sectionId).scrollIntoView({ behavior: 'smooth' });
    handleClose();

    console.log('Trying to scroll to:', sectionId);
    const element = document.getElementById(sectionId);
    console.log('Found element:', element);

    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      handleClose();
    } else {
      console.error('Element not found for ID:', sectionId);
    }
  };
  return (
    <S.ModalOverlay
      style={{
        opacity: isVisible ? 1 : 0,
        pointerEvents: isVisible ? 'auto' : 'none',
      }}
      onClick={handleClose}
    >
      <S.ModalContent
        style={{ transform: isVisible ? 'translateX(0)' : 'translateX(100%)' }}
        onClick={(e) => e.stopPropagation()}
      >
        <S.MenuListing>
          <S.ItemMenu onClick={() => handleMenuItemClick('casesSection')}>
            cases
          </S.ItemMenu>
          <S.ItemMenu onClick={() => handleMenuItemClick('solutionsSection')}>
            soluções
          </S.ItemMenu>
          <S.ItemMenu onClick={() => handleMenuItemClick('sanchesSection')}>
            sobre
          </S.ItemMenu>
          <S.ItemMenu
            onClick={() => handleMenuItemClick('testimonialsSection')}
          >
            depoimentos
          </S.ItemMenu>
          <S.ItemMenu onClick={() => handleMenuItemClick('contactSection')}>
            contato
          </S.ItemMenu>
        </S.MenuListing>
        <S.CloseButton onClick={handleClose}>
          <IoClose />
        </S.CloseButton>
      </S.ModalContent>
    </S.ModalOverlay>
  );
}
