import styled, { css } from 'styled-components';

export const Footer = styled.div`
  width: 100%;
  display: flex;
  background-color: #434957;
  align-items: center;
  justify-content: space-between;
  padding: 2em;
  gap: 1em;
  box-sizing: border-box;
`;

export const Logo = styled.div`
  width: 100%;
  display: flex;
  color: #25272b;
  align-items: center;
  gap: 1em;
  justify-content: flex-start;

  svg {
    width: 24.099px;
    height: 34.409px;
    flex-shrink: 0;
  }
`;

export const StartProject = styled.button`
  border: none;
  font-size: 15px;
  background-color: #f1ffee;
  border: 1px solid #434957;
  color: #434957;
  width: 322px;
  height: 68px;
  border-radius: 12px;
  padding: 16px 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  flex-shrink: 0;
  transition: all 0.3s ease;

  &:hover {
    opacity: 0.9;
    background-color: #434957;
    border: 1px solid #f1ffee;
    color: #f1ffee;
  }

  svg {
    font-size: 22px;
  }
`;

export const SocialMedias = styled.div`
  background-color: #393f4d;
  display: flex;
  width: 160px;
  height: 54px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  gap: 1em;
  background: #393f4d;
  transition: all 0.3s ease;

  svg {
    fill: #fff;
    font-size: 21px;
    &:hover {
      fill: #f1ffee;
      opacity: 0.9;
      scale: 1.1;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #ffffff50;
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  transition: opacity 400ms ease;
  opacity: 0;
  pointer-events: none;
  z-index: 30;
`;

export const ModalContent = styled.div`
  background: #434957;
  padding: 0;
  color: #f1ffee;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transform: translateX(100%);
  transition: transform 200ms ease;

  @media (max-width: 768px) {
    width: 88%;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    display: flex;
    padding-bottom: 4em;
  }
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  position: absolute;
  right: 20px;
  top: 35px;
  cursor: pointer;
  transition: all 0.3s ease;

  svg {
    fill: #fff;
    font-size: 32px;
    color: #fff;
    opacity: 1;

    &:hover {
      opacity: 0.6;
    }
  }
`;

export const MenuListing = styled.div`
  padding: 4em 4em 0;
  display: flex;
  flex-direction: column;

  gap: 1em;
  width: 100%;
  box-sizing: border-box;
  text-align: left;

  @media (max-width: 768px) {
    padding: 3em 3em 0;
  }
`;

export const ItemMenu = styled.button`
  text-align: left;
  padding: 0em;
  background-color: transparent;
  border: none;
  color: #f1ffee;
  font-size: 48px;
  transition: all 0.5s ease;

  &:hover {
    opacity: 0.2;
  }

  @media (max-width: 768px) {
    font-size: 40px;
    text-align: left;
    line-height: 1.5em;
    width: 100%;
    display: flex;
  }
`;

export const LogoImage = styled.img`
  display: flex;
`;

export const HeaderContainer = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 65px;
  padding: 1em 5em;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  transition: all 0.3s ease;
  z-index: 10;
  width: 100%;
  background-color: ${(props) =>
    props.isScrolled ? '#f1ffee' : 'transparent'};

  ${(props) =>
    props.show
      ? css`
          transform: translateY(0);
        `
      : css`
          transform: translateY(-100%);
        `}

  @media (max-width: 768px) {
  }

  padding: ${(props) => (props.isScrolled ? '1em 2em' : '0em 2em')};
`;

export const MenuButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: #f1ffee;

  svg {
    font-size: 32px;
    transition: all 0.3s ease;

    &:hover {
      scale: 0.98;
      opacity: 0.2;
    }
  }

  @media (max-width: 768px) {
    svg {
      color: #434957;
    }
  }
`;

export const FooterContent = styled.div`
  display: flex;
  gap: 1em;
  box-sizing: border-box;
  padding: 0 4em;
  justify-content: space-between;
  position: absolute;
  bottom: 3em;
  flex-direction: row;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0 3em;
  }
`;

export const TalkSanches = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 0.5em;
`;

export const IconImage = styled.img`
  width: 100%;
  display: flex;
`;

export const IconTalk = styled.div`
  background-color: #343a49;
  height: 54px;
  min-width: 54px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  justify-content: center;
  position: relative;

  &::before {
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: red;
    height: 15px;
    z-index: 9;
    width: 15px;
  }
`;

export const ButtonTalk = styled.button`
  background-color: #ffffff;
  height: 54px;
  border: none;
  border-radius: 8px;
  color: #434957;
  border: 1px solid #fff;
  transition: all 0.3s ease;
  width: 100%;

  &:hover {
    background-color: #434957;
    color: #fff;
    border: 1px solid #fff;
  }
`;
