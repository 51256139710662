// styles.js
import styled from 'styled-components';

export const GlobalLeadContainer = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 0.3em;
  background-color: #f1ffee;
  border-radius: 120px;
  z-index: 28;
  cursor: pointer;
  width: fit-content;
  box-shadow: -3px 0px 9px 0px rgb(78 93 74 / 17%);
  transition: all 0.3s ease;

  &:hover {
    /* background-color: #434957; */
    color: #fff;
    scale: 1.1;
    svg {
      fill: #fff;
    }
  }
`;

export const ContentProfile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0;
  padding-right: 1em;
  /* 
  &.active {
    display: none;
  } */
  /* 
  @media screen and (max-width: 768px) {
    display: none;
  } */
`;
export const FixedButton = styled.button`
  border: none;
  border-radius: 12px;
  align-items: center;
  gap: 1em;
  background-color: transparent;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin: 0;
  padding: 0;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: -5px;
  right: 7px;
  border: none;
  background-color: #fff;
  height: 21px;
  width: 22px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: -3px 0px 9px 0px rgb(78 93 74 / 17%);
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    /* background-color: #434957; */
    color: #fff;
  }
`;

export const Profile = styled.img``;
export const ProfileImage = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #434957;
  border-radius: 120px;
`;

export const Title = styled.h1`
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  color: #434957;
  margin: 0;
`;

export const SubTitle = styled.h3`
  color: #434957;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  margin: 0;
`;

export const ContactForm = styled.form`
  display: none;
  display: flex;
  flex-direction: column;
  margin-top: 0.8em;
`;

export const Input = styled.input`
  height: 48px;
  padding: 0 1.8em;
  background-color: #fff;
  color: white;
  border: none;
  border-radius: 12px;
  color: #434957;
  margin-top: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.3s ease;
  font-size: 11px;

  &:hover {
    background-color: #fff;
  }
`;

export const SubmitButton = styled.button`
  height: 54px;
  padding: 0 1.8em;
  background-color: #434957;
  color: #fff;
  border: none;
  font-size: 12px;
  border-radius: 12px;

  cursor: pointer;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.3s ease;

  &:hover {
    background-color: #fff;
    color: #434957;
  }
`;
