import { FaFacebookF, FaInstagram } from 'react-icons/fa';
import { TiSocialLinkedin } from 'react-icons/ti';
import * as S from './styles';

const iconSanches = '/assets/footer/logo.svg';

import { useState } from 'react';

export default function Footer() {
  const [clickedSocialMedia, setClickedSocialMedia] = useState('');

  const handleSocialMediaClick = (socialMedia) => {
    setClickedSocialMedia(socialMedia);

    switch (socialMedia) {
      case 'Facebook':
        window.open('https://www.facebook.com/sanches.io', '_blank');
        break;
      case 'Instagram':
        window.open('https://www.instagram.com/saanches.io', '_blank');
        break;
      case 'LinkedIn':
        window.open('https://www.linkedin.com/company/sanchesio', '_blank');
        break;
      default:
        break;
    }
  };

  return (
    <>
      <S.Footer>
        <S.Logo>
          <S.IconSanches src={iconSanches} width={25} alt='Logo Sanches' />
          Criado e desenvolvido por Sanches {'<>'}
        </S.Logo>

        <S.SocialMedias>
          <FaFacebookF onClick={() => handleSocialMediaClick('Facebook')} />
          <FaInstagram onClick={() => handleSocialMediaClick('Instagram')} />
          <TiSocialLinkedin
            onClick={() => handleSocialMediaClick('LinkedIn')}
          />
        </S.SocialMedias>
      </S.Footer>
    </>
  );
}
