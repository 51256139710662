import { useEffect } from 'react';

const GoogleAnalytics = () => {
  useEffect(() => {
    // Cria o script gtag.js
    const script = document.createElement('script');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-RJCXL8GSPH';
    script.async = true;
    document.head.appendChild(script);

    // Cria o script de inicialização
    const scriptInit = document.createElement('script');
    scriptInit.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-RJCXL8GSPH');
    `;
    document.head.appendChild(scriptInit);

    return () => {
      // Limpeza (opcional)
      document.head.removeChild(script);
      document.head.removeChild(scriptInit);
    };
  }, []);

  return null;
};

export default GoogleAnalytics;
