import React from 'react';
import Banner from '../../components/Homepage/Banner';
import DigitalPresence from '../../components/Homepage/DigitalPresence';
import SkillsSlider from '../../components/Homepage/SkillsSlider';
import Solutions from '../../components/Homepage/Solutions';
import StayConnected from '../../components/Homepage/StayConnected';

const HomePage = () => {
  return (
    <>
      <Banner />
      <SkillsSlider id='skillsSliderSection' />
      {/* <Cases />
      <ScrollableInfoBanner /> */}

      <Solutions />
      {/* <Testimonials /> */}
      <DigitalPresence />
      <StayConnected />
    </>
  );
};

export default HomePage;
