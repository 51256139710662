import * as S from './styles';
const skillSet = [
  // Habilidades listadas uma vez
  {
    title: 'Transforme sua presença digital',
    icon: '/assets/homepage/skills/transform.svg',
  },
  {
    title: 'Arquitetura de Projetos Avançada',
    icon: '/assets/homepage/skills/architecture.svg',
  },
  {
    title: 'Modelos de Negócio Inovadores',
    icon: '/assets/homepage/skills/business-model.svg',
  },
  {
    title: 'Design UX & UI Autêntico',
    icon: '/assets/homepage/skills/ux-ui.svg',
  },
  {
    title: 'Tecnologia Personalizada sob Demanda ',
    icon: '/assets/homepage/skills/dev.svg',
  },
];

const SkillsSlider = () => {
  return (
    <S.SliderContainer>
      {/* Duplicar o conjunto de habilidades para garantir a transição suave no marquee */}
      {[...skillSet, ...skillSet].map((skill, index) => (
        <S.Slide key={index}>
          <S.SlideContent>
            <S.Icon src={skill.icon} alt={skill.title} />
            <S.SkillTitle>{skill.title}</S.SkillTitle>
          </S.SlideContent>
        </S.Slide>
      ))}
    </S.SliderContainer>
  );
};

export default SkillsSlider;
