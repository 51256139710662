import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Elza Text Light';
    src: url('/fonts/elza/ElzaText-Light.woff') format('woff');
    font-weight: lighter;
    font-style: normal;
  }
 @font-face {
    font-family: 'Elza Text Regular';
    src: url('/fonts/elza/ElzaText-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Elza Text Medium';
    src: url('/fonts/elza/ElzaText-Medium.woff') format('woff');
    font-weight: 600; // Assuming "Semibold" is weight 600
    font-style: normal;
  }
  @font-face {
    font-family: 'Elza Text Semibold';
    src: url('/fonts/elza/ElzaText-Semibold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Elza Text Bold';
    src: url('/fonts/elza/ElzaText-Bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }


  :root {
  --foreground-rgb: #fff;
  --background-start-rgb: #fff;
  --background-end-rgb: #fff;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: #fff;
    --background-start-rgb: #fff;
    --background-end-rgb: #fff;
  }
}

* {
  box-sizing: border-box;
}
body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
  width: 100%;
  margin: 0;
  font-family: 'Elza Text', sans-serif;

}  
    .-no-overflow {
      overflow: hidden;
    }
`;
