import BlankTemplate from '../templates/Blank';

import homepage from '../pages/homepage';

export const routes = [
  // BUYING FLUX

  {
    path: '/',
    page: homepage,
    title: 'homepage',
    isPublic: true,
    template: BlankTemplate,
  },
];
