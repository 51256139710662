import * as S from './styles';

const whatsappNumber = '+5511942413999'; // Replace with your WhatsApp number
const iconLightGreen = '/assets/header/icon-light-green.svg';

export default function GlobalLead() {
  const handleWhatsAppRedirect = () => {
    const whatsappMessage = `Olá, gostaria de mais informações.`;
    const whatsappUrl = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(
      whatsappMessage,
    )}`;
    window.open(whatsappUrl, '_blank');
  };

  return (
    <S.GlobalLeadContainer>
      <S.FixedButton onClick={handleWhatsAppRedirect}>
        <S.ProfileImage>
          <S.Profile src={iconLightGreen} width={20} alt='Logo Sanches' />
        </S.ProfileImage>
        {/* <S.ContentProfile>
          <S.Title>Olá, sou especialista</S.Title>
          <S.SubTitle>Estou no WhatsApp se precisar</S.SubTitle>
        </S.ContentProfile> */}
      </S.FixedButton>
    </S.GlobalLeadContainer>
  );
}
