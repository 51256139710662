// style.js
import styled from 'styled-components';

export const StayConnectedContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  align-items: center;
  background: #f1ffee;
  justify-content: space-between;
  padding: 0 4em;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0;
  }
`;

export const RevertColumn = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  @media (max-width: 768px) {
    display: flex;
    width: 100%;
    flex-direction: column-reverse;
  }
`;
export const ImageColumn = styled.div`
  width: 50%;
  background-image: url('/assets/homepage/stay-connected.jpg'); // Caminho para a imagem de fundo
  background-size: cover; // Garante que a imagem cubra toda a área
  background-position: center; // Centraliza a imagem
  border-radius: 0; // Se desejar bordas arredondadas
  min-height: 500px; // Altura mínima para garantir que a coluna tenha altura suficiente

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const ContentColumn = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  background-color: #f1ffee;
  align-items: center;
  padding: 0.2em 2.8em;
  height: 100%;
  border-radius: 21px;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    justify-content: space-around;
    padding: 4em;
    box-sizing: border-box;
  }
`;
export const TextSpace = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2em;
  letter-spacing: 2px;
  font-size: 14px;
  margin-bottom: 2em;
`;

export const Text = styled.p`
  color: #434957;
  padding-right: 2em;
  font-size: 19.218px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 28.826px */
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2em;
  width: 30%;
  padding-right: 2em;

  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 4em;
  }
`;

export const Title = styled.h1`
  text-align: left;
  font-weight: 500;
  color: #434957;
  margin: 0;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;

  @media (max-width: 768px) {
    font-size: 24px;
  }
`;
export const SubTitle = styled.h3`
  text-align: left;
  color: #434957;
  font-size: 18px;
  font-weight: 500;
  margin-top: 5px;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const FooterContainer = styled.footer`
  background-color: #f1f1f1; // Replace with actual color code from your design
  padding: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
`;

export const InlineRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const FormField = styled.div`
  display: flex;
  flex-direction: column;
  border: none;
  width: 100%;
`;

export const Label = styled.label`
  font-size: 0.6rem;
  color: #333; // Replace with actual color code from your design
  margin-bottom: 0.5rem;
`;

export const Input = styled.input`
  padding: 1em 1.5em;
  border: 1px solid #ccc; // Replace with actual color code from your design
  border-radius: 8px;
  background-color: #d4ffcb;
  border: none;
  height: 40px;
`;

export const TextArea = styled.textarea`
  padding: 1em 1.5em;
  border-radius: 8px;
  background-color: #d4ffcb;
  border: none;

  border-radius: 8px;
  background-color: #d4ffcb;
  border: none;
  width: 100%;
`;

export const FooterBrand = styled.div`
  // Add styles for your footer brand/logo area
`;

export const SubmitButton = styled.button`
  border: none;
  font-size: 15px;
  width: 100%;
  height: 60px;
  border-radius: 12px;
  padding: 16px 32px;
  display: flex;
  border: none;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  flex-shrink: 0;
  transition: all 0.3s ease;
  cursor: pointer;
  background-color: #434957;
  border: 1px solid #f1ffee;
  color: #f1ffee;

  &:hover {
    opacity: 0.9;
    background-color: #f1ffee;
    border: 1px solid #434957;
    color: #434957;
    svg {
      fill: #f1ffee;
    }
  }

  svg {
    font-size: 22px;
    fill: #434957;
  }
`;
